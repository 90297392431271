import * as tslib_1 from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { NAV_LIST } from '@/common/js/constant/index.ts';
let Nav = class Nav extends Vue {
    constructor() {
        super(...arguments);
        this.NAV_LIST = NAV_LIST.filter((item) => item.platform.includes('desktop'));
    }
    get curNav() {
        const routeList = this.$route.matched;
        // 从本路由开始向上级匹配路由菜单
        for (const route of routeList) {
            for (const nav of NAV_LIST) {
                if (nav.name === route.name) {
                    return [nav.name];
                }
            }
        }
        return [];
    }
    set curNav(val) { }
};
Nav = tslib_1.__decorate([
    Component
], Nav);
export default Nav;
